.header {
	font-size: 1.125rem;

	p {
		letter-spacing: -0.01em;
		margin: 0;
	}

	&__top,
	&__bottom {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 1rem;
	}

	&__top {
		justify-content: flex-end;

		h1 {
			margin-right: auto;
		}
	}

	&__bottom {
		justify-content: space-between;
	}
}
