.grid {
	position: relative;
	z-index: 1;

	&__row {
		display: flex;
		margin: 0 -0.5rem 1rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__cell {
		width: calc(25% - 1rem);
		height: 0;
		padding-bottom: calc(25% - 1rem);
		margin: 0 0.5rem;
		background: rgba(#776e65, 0.35);
		border-radius: 0.25rem;

		&:last-child {
			margin-right: 0;
		}
	}
}
