@keyframes move-up {
  0% {
    opacity: 1;
    top: 1.5625rem;
  }

  100% {
    opacity: 0;
    top: -3.125rem;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes pop {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

html, body {
  color: #776e65;
  background-color: #fff;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: monospace;
  font-size: 1rem;
  line-height: 1.25;
}

h1 {
  margin: 0;
  font-size: 2.5rem;
  line-height: 1;
}

.container {
  max-width: 38.924rem;
  margin: 0 auto;
  padding: 1rem;
}

.game {
  box-sizing: border-box;
  cursor: default;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #bbada0;
  border-radius: .5rem;
  padding: 1rem;
  position: relative;
}

.header {
  font-size: 1.125rem;
}

.header p {
  letter-spacing: -.01em;
  margin: 0;
}

.header__top, .header__bottom {
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  display: -ms-flexbox;
  display: flex;
}

.header__top {
  justify-content: flex-end;
}

.header__top h1 {
  margin-right: auto;
}

.header__bottom {
  justify-content: space-between;
}

.score {
  color: #fff;
  text-align: center;
  background: #bbada0;
  border-radius: .25rem;
  padding: .5rem 1rem;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1;
  position: relative;
}

.score:before {
  color: #eee4da;
  text-transform: uppercase;
  padding-bottom: .5rem;
  font-size: .875rem;
  line-height: 1;
  display: block;
}

.score-addition {
  color: #776e65e6;
  z-index: 2;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1;
  animation: .6s ease-in both move-up;
  position: absolute;
  right: 30px;
}

.score.current-score {
  border-right: 1px solid #fff;
  border-radius: .25rem 0 0 .25rem;
}

.score.current-score:before {
  content: "Score";
}

.score.best-score {
  border-radius: 0 .25rem .25rem 0;
}

.score.best-score:before {
  content: "Best";
}

.retry {
  color: #f9f6f2;
  text-transform: uppercase;
  box-shadow: none;
  -ms-appearance: none;
  appearance: none;
  background: #8f7a66;
  border: 0;
  border-radius: .25rem;
  padding: .8rem 1.25rem .7rem;
  font-family: inherit;
  font-size: 1.125rem;
  line-height: 1;
  text-decoration: none;
  display: block;
}

.grid {
  z-index: 1;
  position: relative;
}

.grid__row {
  margin: 0 -.5rem 1rem;
  display: -ms-flexbox;
  display: flex;
}

.grid__row:last-child {
  margin-bottom: 0;
}

.grid__cell {
  background: #776e6559;
  border-radius: .25rem;
  width: calc(25% - 1rem);
  height: 0;
  margin: 0 .5rem;
  padding-bottom: calc(25% - 1rem);
}

.grid__cell:last-child {
  margin-right: 0;
}

.tile {
  text-align: center;
  z-index: 10;
  background: #eee4da;
  border-radius: .25rem;
  width: calc(25% - 1rem);
  height: 0;
  padding-bottom: calc(25% - 1rem);
  font-size: 3.4375rem;
  font-weight: 700;
  line-height: 2.6;
  transition: top .4s ease-in-out, left .4s ease-in-out;
  position: absolute;
}

.tiles {
  z-index: 2;
  width: calc(100% - 1rem);
  height: calc(100% - 1rem);
  position: absolute;
  top: .5rem;
  left: .5rem;
}

.tile.tile-position-1-1 {
  top: .5rem;
  left: .5rem;
}

.tile.tile-position-1-2 {
  top: calc(25% + .5rem);
  left: .5rem;
}

.tile.tile-position-1-3 {
  top: calc(50% + .5rem);
  left: .5rem;
}

.tile.tile-position-1-4 {
  top: calc(75% + .5rem);
  left: .5rem;
}

.tile.tile-position-2-1 {
  top: .5rem;
  left: calc(25% + .5rem);
}

.tile.tile-position-2-2 {
  top: calc(25% + .5rem);
  left: calc(25% + .5rem);
}

.tile.tile-position-2-3 {
  top: calc(50% + .5rem);
  left: calc(25% + .5rem);
}

.tile.tile-position-2-4 {
  top: calc(75% + .5rem);
  left: calc(25% + .5rem);
}

.tile.tile-position-3-1 {
  top: .5rem;
  left: calc(50% + .5rem);
}

.tile.tile-position-3-2 {
  top: calc(25% + .5rem);
  left: calc(50% + .5rem);
}

.tile.tile-position-3-3 {
  top: calc(50% + .5rem);
  left: calc(50% + .5rem);
}

.tile.tile-position-3-4 {
  top: calc(75% + .5rem);
  left: calc(50% + .5rem);
}

.tile.tile-position-4-1 {
  top: .5rem;
  left: calc(75% + .5rem);
}

.tile.tile-position-4-2 {
  top: calc(25% + .5rem);
  left: calc(75% + .5rem);
}

.tile.tile-position-4-3 {
  top: calc(50% + .5rem);
  left: calc(75% + .5rem);
}

.tile.tile-position-4-4 {
  top: calc(75% + .5rem);
  left: calc(75% + .5rem);
}

.tile.tile-2 {
  background: #eee4da;
  box-shadow: 0 0 30px 10px #f3d77400, inset 0 0 0 1px #fff0;
}

.tile.tile-4 {
  background: #eee1c9;
  box-shadow: 0 0 30px 10px #f3d77400, inset 0 0 0 1px #fff0;
}

.tile.tile-8 {
  color: #f9f6f2;
  background: #f3b27a;
}

.tile.tile-16 {
  color: #f9f6f2;
  background: #f69664;
}

.tile.tile-32 {
  color: #f9f6f2;
  background: #f77d5f;
}

.tile.tile-64 {
  color: #f9f6f2;
  background: #f75f3b;
}

.tile.tile-128 {
  color: #f9f6f2;
  background: #edd073;
  font-size: 45px;
  box-shadow: 0 0 30px 10px #f3d7743d, inset 0 0 0 1px #ffffff24;
}

@media screen and (width <= 767px) {
  .tile.tile-128 {
    font-size: 25px;
    line-height: 3;
  }
}

.tile.tile-256 {
  color: #f9f6f2;
  background: #edcc62;
  font-size: 45px;
  box-shadow: 0 0 30px 10px #f3d77451, inset 0 0 0 1px #ffffff31;
}

@media screen and (width <= 767px) {
  .tile.tile-256 {
    font-size: 25px;
    line-height: 3;
  }
}

.tile.tile-512 {
  color: #f9f6f2;
  background: #edc950;
  font-size: 45px;
  box-shadow: 0 0 30px 10px #f3d77465, inset 0 0 0 1px #ffffff3d;
}

@media screen and (width <= 767px) {
  .tile.tile-512 {
    font-size: 25px;
    line-height: 3;
  }
}

.tile.tile-1024 {
  color: #f9f6f2;
  background: #edc53f;
  font-size: 35px;
  box-shadow: 0 0 30px 10px #f3d77479, inset 0 0 0 1px #ffffff49;
}

@media screen and (width <= 767px) {
  .tile.tile-1024 {
    font-size: 20px;
    line-height: 3.75;
  }
}

.tile.tile-2048 {
  color: #f9f6f2;
  background: #edc22e;
  font-size: 35px;
  box-shadow: 0 0 30px 10px #f3d7748e, inset 0 0 0 1px #fff5;
}

@media screen and (width <= 767px) {
  .tile.tile-2048 {
    font-size: 20px;
    line-height: 3.75;
  }
}

.tile-new {
  animation: .2s .4s both appear;
}

.tile-merged {
  z-index: 20;
  animation: .2s .4s both pop;
}

@media screen and (width <= 767px) {
  .tile {
    font-size: 30px;
  }
}

.message {
  text-align: center;
  z-index: 3;
  background: #eee4dabf;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  font-size: 3.75rem;
  font-weight: 700;
  line-height: 1;
  animation: .8s .4s both fade-in;
  display: none;
  position: absolute;
  inset: 0;
}

.message p {
  margin: 2rem;
}

.message__actions {
  margin-top: 2rem;
  display: block;
}

.message.game-won {
  color: #f9f6f2;
  background: #edc22ebf;
}

.message.game-won, .message.game-over {
  display: -ms-flexbox;
  display: flex;
}

social-links {
  color: #776e65d9;
  display: block;
}
/*# sourceMappingURL=index.986a2f0c.css.map */
